import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PortalService } from '@trato-logistica/trato-web-ui-hml';
import { Observable } from 'rxjs';
import { ReviewComponent } from '../shell/components/review/review.component';
import { ReviewService } from '../shell/components/review/services/review.service';

@Injectable({
    providedIn: 'root'
})
export class ReviewInterceptor implements HttpInterceptor {

    private readonly NUMBER_REQUEST_TO_CHECK_FOR_REVIEW: number = 60;
    private _countRequestsMade = 0;

    constructor(private reviewService: ReviewService, private portalService: PortalService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this._countRequestsMade++;
        const hasEnoughRequests = this._countRequestsMade > this.NUMBER_REQUEST_TO_CHECK_FOR_REVIEW;
        if (hasEnoughRequests) {
          this._countRequestsMade = 0;
          this.reviewService.getOptions().subscribe({
            next: (reviews) => {
                if (!!reviews?.length) {
                  this.portalService.openComponent(
                    ReviewComponent
                  );
                }
              }
            });
        }
        return next.handle(req);
    }
}
