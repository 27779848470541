import { Injectable } from '@angular/core';
import { HttpRequest, HttpEvent, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CryptographyService } from 'sharedCommon/services/util/cryptography.service';
import { QuotaVolumeFilter } from 'sat/quota-volume/interfaces/quota-volume-filter.interface';

@Injectable()
export class QuotaVolumeInterceptor implements HttpInterceptor {
  constructor(private cryptographyService: CryptographyService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<QuotaVolumeFilter>> {
    if (request.url.endsWith('/quotas')) {
      //CRIPTOGRAFIA
      if (request?.body?.traders) {
        request.body.traders = request.body.traders.map((trader) => {
          return this.cryptographyService.encrypt(trader.toString());
        });
      } else {
        return next.handle(request);
      }
    }
    return next.handle(request);
  }
}
