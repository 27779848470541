import { apiSetting, softwareVersioning } from './connections/connections.env';
import { environmentSettings } from './connections/environment.type';
import { GoogleConnections } from './connections/google.connections';
import { keyStg } from './key/key';

const typeEnvironment = environmentSettings('STG');
const PATH = typeEnvironment.path;
const connections = new GoogleConnections();
const DEFAULT_SIZE_REQUEST = 20;
const API = apiSetting(PATH);
const googleSettings = connections.googleSettings(keyStg);
const firebaseSettings = connections.firebaseSettings(keyStg, 'STG');

const KEYCLOAK_CONFIG = {
  url: typeEnvironment.url,
  realm: 'trato',
  clientId: 'web',
};

export const environment = {
  production: false,
  API,
  googleSettings,
  firebaseSettings,
  DEFAULT_SIZE_REQUEST,
  KEYCLOAK_CONFIG,
  version: softwareVersioning,
};
