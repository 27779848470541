import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SelectData } from '@trato-logistica/trato-web-ui-hml';
import { environment } from 'env/environment';
import { Observable } from 'rxjs';
import { TruckDriver } from 'sharedCommon/entities/truck-driver/truck-driver.interface';
import { Truck } from 'sharedCommon/entities/truck/truck.interface';
import { CryptographyService } from 'sharedCommon/services/util/cryptography.service';
import { SchedulingData } from 'sharedSat/state/scheduling/scheduling-data.interface';
import { ENCRYPT_CPF } from '../constants/chapa.constant';
import { chapaDriver } from '../interfaces/scheduling-chapa-driver.interface';
import { Driver } from '../models/driver.model';


@Injectable({
  providedIn: 'root',
})
export class ChapaService {
  private readonly apiChapa = environment.API.TRUCK_DRIVER_CHAPA;
  private readonly apiChapa2 = environment.API.TRUCK_PLATE_CHAPA;
  private readonly apiChapaSupervisor = environment.API.TRUCK_PLATE_SAVE_CHAPA;
  private _id: string;
  private _cpf: string;
  private _name: string;
  private _phone: string;
  private _step: number;
  private _allSteps: number;
  private _registeredOnTruckDriver: boolean;
  private _chapaDriver: chapaDriver;
  private _type_editable ;
  private _editableDriver ;
  private _existsOnTruckDriverChapaNow;
  private _isOwner: boolean;
  private _inviteLink: boolean;
  private _plateDriverScheduling: Truck;
  toggleSchedulling$ = this.toggleSchedulling();
  private _enableNewScheduling: boolean;
  private _truckDriver : TruckDriver;
  private _routeScheduler:string;
  private _scheduleStoreInfo:SchedulingData;

  constructor(private httpClient: HttpClient, private cryptographyService: CryptographyService) { }

  get isScheduleStoreInfo():SchedulingData {
    return this._scheduleStoreInfo;
  }

  set isScheduleStoreInfo(value : SchedulingData) {
     this._scheduleStoreInfo = value;
  }

  get isRouteScheduler():string {
    return this._routeScheduler;
  }

  set isRouteScheduler(value:string){
    this._routeScheduler = value;
  }

  get isOwner(): boolean {
    return this._isOwner;
  }

  set isOwner(value: boolean) {
    this._isOwner = value;
  }

  get truckDriver(): TruckDriver {
    return this._truckDriver;
  }

  set truckDriver(value: TruckDriver) {
    this._truckDriver = value;
  }

  get enableNewScheduling(): boolean {
    return this._enableNewScheduling;
  }

  set enableNewScheduling(value: boolean) {
    this._enableNewScheduling = value;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }
  get cpf(): string {
    return this._cpf;
  }

  set cpf(value: string) {
    this._cpf = value;
  }

  get phone(): string {
    return this._phone;
  }

  set phone(phone: string) {
    this._phone = phone;
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  get typeEditable(): boolean {
    return this._type_editable;
  }

  set typeEditable(editable: boolean) {
    this._type_editable = editable;
  }

  get step(): number {
    return this._step;
  }
  get editableDriver() :boolean{
      return this._editableDriver
  }

  set editableDriver(editableDriver: boolean){
    this._editableDriver = editableDriver
  }

  set step(value: number) {
    this._step = value;
  }

  get allSteps(): number {
    return this._allSteps;
  }

  set allSteps(value: number) {
    this._allSteps = value;
  }

  get registeredOnTruckDriver(): boolean {
    return this._registeredOnTruckDriver;
  }

  set registeredOnTruckDriver(value: boolean) {
    this._registeredOnTruckDriver = value;
  }

  get existsOnTruckDriverChapaNow():boolean{
    return this._existsOnTruckDriverChapaNow
  }

  set existsOnTruckDriverChapaNow(value :boolean ){
    this._existsOnTruckDriverChapaNow = value;
  }

  get inviteLink():boolean{
    return this._inviteLink
  }

  set inviteLink(value :boolean ){
    this._inviteLink = value;
  }

  getStatusCpf(cpf: string): Observable<any> {
    return this.httpClient.get<any>(`${this.apiChapa}/pre-registration-basic/status`, { headers: this.cpfHeader(cpf) });
  }

  sendDataDriver(driver: Driver): Observable<any> {
    return this.httpClient.post<any>(`${this.apiChapa}/pre-registration-basic`, driver);
  }



  updateDataDriver(driver: Driver): Observable<any> {
    return this.httpClient.put<any>(`${this.apiChapa}/pre-registration-basic`, driver);
  }

  getDriverCpf(cpf: string): Observable<any> {
    return this.httpClient.get<any>(`${this.apiChapa}/pre-registration-basic`,
      { headers: this.cpfHeader(cpf) }
    );
  }

  updateDataDriverChangeType(type:number): Observable<any> {
    const payload = {
      cpf: this.cpf,
      type: type,
    };
    return this.httpClient.post<any>(`${this.apiChapa}/pre-registration-basic/change-type/origin/BUDDY`, payload
    );
  }

  startChapa(): Observable<any> {
    return this.httpClient.get<any>(`${this.apiChapa}/pre-registration-basic/start`,
      { headers: this.cpfHeader() }
    );
  }

  getDriverCarreir(cpf: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.apiChapa}/pre-registration/truck-driver-carrier`,
      { headers: this.cpfHeader(cpf) }
    );
  }

  getCategories(term: string = null): SelectData[] {
    let items = [
      { id: 'AC', label: 'AC' },
      { id: 'AD', label: 'AD' },
      { id: 'AE', label: 'AE' },
      { id: 'C', label: 'C' },
      { id: 'D', label: 'D' },
      { id: 'E', label: 'E' },
    ];
    if (term) {
      items = items.filter((x) => x.label.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
    }
    return items;
  }

  encryptCpf(cpf: string = this.cpf): string {
    if (ENCRYPT_CPF) {
      return this.cryptographyService.encrypt(cpf);
    }
    return cpf;
  }

  get chapaDriver(): chapaDriver {
    return this._chapaDriver;
  }

  set chapaDriver(chapaDriver: chapaDriver) {
    this._chapaDriver = chapaDriver;
  }

  get plateDriver(): any{
    return this._plateDriverScheduling;
  }

  set plateDriver(plateDriver:any) {
      this._plateDriverScheduling = plateDriver;
  }

  sendDataPlateScheduling(driver: any): Observable<any> {
    return this.httpClient.post<any>(`${this.apiChapaSupervisor}/event/truck`, driver);
  }

  toggleSchedulling( ): Observable<any> {
    return this.httpClient.get<any>(`${this.apiChapa}/config-register/check-buddy-enable` );
  }

  searchByCpf(cpf: string): Observable<any> {
    return this.httpClient.get<any>(`${this.apiChapa}/truck-driver/searchByCpf`, { headers: this.cpfHeader(cpf) });
  }

  cpfHeader(cpf?: any): HttpHeaders {
    return new HttpHeaders({ cpf: this.encryptCpf(cpf) });
  }

  getTruckPlate(plate: string): Observable<any> {
    return this.httpClient.get<any>(`${this.apiChapa2}/trucks?plateNumber=${plate}`);
  }

  getValidateTerminal(terminal:string): Observable<any> {
    return this.httpClient.get<any>(`${this.apiChapa}/pre-registration/terminal/${terminal}/full-registration-enabled`);
  }
}
