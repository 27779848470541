import { Component } from '@angular/core';
import { ModalFeedbackType, ModalFeedbackView } from '@trato-logistica/trato-web-ui-hml';
import { ModalFeedbackBaseComponent } from '../modal-feedback-base';

@Component({
  templateUrl: '../modal-feedback-base.html',
  styleUrls: ['./generic-question.component.scss'],
})
export class ModalGenericQuestionComponent extends ModalFeedbackBaseComponent {
  get type(): ModalFeedbackType {
    return this.data?.icon || ModalFeedbackView.QUESTION;
  }

  get confirmText(): string {
    return this.data?.confirmText || 'Sim';
  }

  get cancelText(): string {
    return this.data?.cancelText || 'Não';
  }
}
