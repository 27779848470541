import { Injectable } from '@angular/core';
import { GenericPortalRef, PortalService } from '@trato-logistica/trato-web-ui-hml';
import { ModalGenericErrorComponent } from '../common/components/modal-feedback/generic-error/generic-error.component';
import { ModalGenericSuccessComponent } from '../common/components/modal-feedback/generic-success/generic-success.component';
import { CONNECTION_OFFLINE_MSG, CONNECTION_ONLINE_MSG } from './connection.constants';
import { ConnectionStore } from './state/connection.store';

/**
 * This service is only intend to reduce code in app.
 * In need to access offline/online current state please consider
 * using the ConnectionStore
 */

@Injectable()
export class ConnectionService {
    private portalRef: GenericPortalRef;

    constructor(
        private portalService: PortalService,
        private connStore: ConnectionStore) { }

    /**
     * Register internal monitor and external for display purposes
     */
    startMonitor(): void {
        this.connStore.startMonitor();
        this.subscribeToStore();
    }

    private subscribeToStore(): void {
        this.connStore.data$.subscribe(connData => {
            this.portalRef?.close();
            if (!connData.showMessage) { return; }
            this.portalRef = this.portalService.openComponent(
                connData.isOnline ? ModalGenericSuccessComponent : ModalGenericErrorComponent,
                connData.isOnline ? CONNECTION_ONLINE_MSG : CONNECTION_OFFLINE_MSG);
        });
    }
}
