import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpStatus } from '@trato-logistica/trato-auth-hml';
import { GenericPortalRef, PortalService } from '@trato-logistica/trato-web-ui-hml';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ModalGenericErrorComponent } from 'src/app/shared/common/components/modal-feedback/generic-error/generic-error.component';
import { TratoError } from 'src/app/shared/common/interfaces/trato-error-response.interface';
import { CONNECTION_OFFLINE_MSG } from 'src/app/shared/connection/connection.constants';
import { ConnectionStore } from 'src/app/shared/connection/state/connection.store';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * Faseamento dos ajustes necessários para os módulos, será removido quando todos estiverem validados
   */
  private routesToIntercept = [
    '/terminal-queue',
    '/settings',
    '/cargo-flex',
    '/sat',
    '/report',
    '/metrics',
    '/records',
    '/chapa',
    '/premium-cadence'
  ];

  private portalRef: GenericPortalRef;

  constructor(private router: Router, private portalService: PortalService, private connectionStore: ConnectionStore) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(this.catchError));
  }

  private catchError = (error: HttpErrorResponse) => {
    if (this.canInterceptRoute(this.router.url) && this.canProcessStatus(error.status)) {
      return throwError(this.processError(error));
    }
    return throwError(error);
  };

  private canInterceptRoute(route: string): boolean {
    return this.routesToIntercept.some((r) => route.startsWith(r));
  }

  private canProcessStatus(status: number): boolean {
    return status === 0 || status >= HttpStatus.INTERNAL_SERVER_ERROR;
  }

  private get isPortalOpen(): boolean {
    return this.portalRef && this.portalRef.overlayRef.hasAttached();
  }

  private processError(error: HttpErrorResponse): TratoError {
    if (this.isPortalOpen) {
      /**
       * Prevents interceptor in opening multiple overlaping modals and controls
       * when navigation occurs
       */
      return;
    }

    delete this.portalRef;

    /**
     * while request being made we can lose connection and receive two messages.
     * based on that we send an action to clear the offline/online message and
     * display it again with the observable attached to provide extra actions
     */
    this.connectionStore.clearState();

    let message: string;

    if (!this.connectionStore.isOnline) {
      message = CONNECTION_OFFLINE_MSG;
    }

    this.portalRef = this.portalService.openComponent(ModalGenericErrorComponent, message);

    return {
      ...error,
      close$: this.portalRef.close$,
      close: () => {
        this.portalRef.close();
      },
    };
  }
}
