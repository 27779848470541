import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  TratoButtonModule,
  TratoFormFieldModule,
  TratoIconModule,
  TratoInputModule,
  TratoModalModule,
  TratoPortalModule,
} from '@trato-logistica/trato-web-ui-hml';
import { ReviewRateContainerComponent } from './review-rate-container/review-rate-container.component';
import { ReviewRateItemComponent } from './review-rate-item/review-rate.component';
import { ReviewComponent } from './review.component';

const REVIEW_COMPONENTS = [ReviewComponent, ReviewRateContainerComponent, ReviewRateItemComponent];
@NgModule({
  declarations: REVIEW_COMPONENTS,
  imports: [
    CommonModule,
    TratoPortalModule,
    TratoModalModule,
    TratoIconModule,
    FormsModule,
    ReactiveFormsModule,
    TratoInputModule,
    TratoFormFieldModule,
    TratoButtonModule,
  ],
  exports: REVIEW_COMPONENTS,
})
export class ReviewFeedbackModule {}
