import { Component } from '@angular/core';
import { ModalFeedbackType, ModalFeedbackView } from '@trato-logistica/trato-web-ui-hml';
import { ModalFeedbackBaseComponent } from '../modal-feedback-base';

@Component({
  templateUrl: '../modal-feedback-base.html',
  styleUrls: ['./generic-info.component.scss'],
})
export class ModalGenericInfoComponent extends ModalFeedbackBaseComponent {
  get type(): ModalFeedbackType {
    return ModalFeedbackView.INFO;
  }
}
