import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@trato-logistica/trato-auth-hml';

const routes: Routes = [{
  path: '',
  loadChildren: () => import('./core/shell/shell.module').then(m => m.ShellModule),
  canActivate: [AuthGuard]
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
