import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TratoLoadingModule } from '@trato-logistica/trato-web-ui-hml';
import { LoaderComponent } from './loader.component';

const COMPONENTS = [
  LoaderComponent
];

@NgModule({
  declarations: [COMPONENTS],
  imports: [
    CommonModule,
    TratoLoadingModule
  ],
  exports: [COMPONENTS]
})
export class LoaderModule { }
