import { Component } from '@angular/core';
import { TratoLoadingAnimationTypes, TratoLoadingConfig } from '@trato-logistica/trato-web-ui-hml';
import { Observable } from 'rxjs';
import { LoaderService } from './loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  loading$: Observable<boolean>;

  loadingConfig: TratoLoadingConfig = {
    animationType: TratoLoadingAnimationTypes.circle,
    backdropBackgroundColour: 'rgba(0,0,0,0.2)',
    backdropBorderRadius: '4px',
    fullScreenBackdrop: true,
    primaryColour: 'var(--primaryColour)',
    secondaryColour: 'var(--secondaryColour)',
    tertiaryColour: 'var(--tertiaryColour)',
  };

  constructor(private loaderService: LoaderService) {
    this.loading$ = this.loaderService.isLoading;
  }
}
